<template>
  <div  style=" ;
                display: flex;flex-direction: row;justify-content: center;">
    <div v-for="item in datalist" :key="item.index"
         style="display: flex;flex-direction: row;
             align-items: center;padding: 2rem;
             ">

      <div style="width:5rem;height:100%;
           display: flex;justify-content: center;flex-direction: column;
                 ">
        <img :src="item.imageurl"
             style="width: 100%;height: 100%;align-items: center">
      </div>
      <div style="margin-left: 1.5rem;">
        <div style="font-size: 1.8rem;font-weight: bold;margin-top: 2rem;
        margin-bottom: 0.5rem;letter-spacing: 0.2rem;text-align: left;
                    color: #2285ff;" v-html="item.title">
        </div>
        <div style="margin-top: 0.5rem;margin-bottom: 3rem;
                    display: flex;flex-direction: column;
                    font-size: 1rem;text-align: left;
                   align-items: center;color: #686868;">
          <p v-for="citem in item.list" :key="citem.index"
             style="padding: 0.1rem;margin: 0" v-html="citem">
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'logotitlebox',
  props: ['datalist'],
};
</script>

<style scoped>

</style>
