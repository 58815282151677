<template>
  <div>
    <solution-header
        :body="body"
        :image="image"
        :show-buy-btn="showBuyBtn"
        :show-detail-btn="showDetailBtn"
        :title="title"
    />
<!--    <ImageList :images="images"></ImageList>-->
    <Paragraph :imgurl="require(`@/assets/image/contentsvg/map.svg`)"
               :title="`行业背景和业务痛点`"
               :en_title="`Industry Background & Business Pain Points`"></Paragraph>
    <div style="width: 80%;margin-left: 10%;background-color:#228BFC;height: 4rem;color:white;
                font-size: 1.8rem;justify-content: center;font-weight: bold; margin-bottom: 4rem;
                display: flex;align-items: center">
      <span>社会信用体系不完善，社会信任机制缺失</span>
    </div>
    <Logolefttitlebox :datalist="datalistx"></Logolefttitlebox>
    <Paragraph :imgurl="require(`@/assets/image/contentsvg/king.svg`)"
               :title="`方案优势`"
               :en_title="`Solution Advantage`"></Paragraph>
    <Logotitlebox :datalist="datalistx2"></Logotitlebox>

    <Paragraph :imgurl="require(`@/assets/image/contentsvg/tikit.svg`)"
               :title="`服务价值`"
               :en_title="`Service Value`"></Paragraph>
  <div style="width: 70%;margin-left: 15%">
    <Lettertitleblock :datalist="datalistx3"
                      style="display: flex;flex-direction: row;justify-content:center ;
                        width: 100%"></Lettertitleblock>
  </div>
    <Paragraph :imgurl="require(`@/assets/image/contentsvg/note.svg`)"
               :title="`应用场景及案例`"
               :en_title="`Application Scenarios & Cases`"></Paragraph>
    <div style="width: 80% ;margin-left: 10%;margin-bottom: 6rem">
      <div style="height: 8rem;margin-bottom: 2rem">
        <img src="@/assets/image/solution/human/logo.png" style="height: 100%">
      </div>
      <div style="font-size: 1.8rem;color: #2285FF;font-weight: bold">服务行业灵活用工全流程交付平台</div>
    </div>
  </div>
</template>

<script>
// import ImageList from '@/components/common/ImageList';
import SolutionHeader from '@/components/common/SolutionHeader.vue';
import Paragraph from '@/components/chain/basic/common/paragraph';
import Logolefttitlebox from '@/components/chain/basic/common/logolefttitlebox';
import Logotitlebox from '@/components/chain/basic/common/logotitlebox';
import Lettertitleblock from '@/components/chain/basic/common/lettertitleblock';

export default {
  name: 'BaaSLeaflet',
  components: {
    // ImageList,
    Paragraph,
    SolutionHeader,
    Logolefttitlebox,
    Logotitlebox,
    Lettertitleblock,
  },
  data() {
    return {
      datalistx3: [
        {
          title: '用人方\n',
          imgsrc: 'A',
          list: [
            '用工追溯、安全用工',
            '减少内部流程节约人力成本',
            '数据分析、人才储备',
            '规范外部公司行为，提高服务质量',
            '行业信息交流',
            '支付依据和工具',
            '',
          ],
        }, {
          title: '中介方\n',
          imgsrc: 'B',
          list: [
            '获客调配+信用拓展',
            '建立自有人才库和团队',
            '内部管理工具，实时跟进',
            '提高公司专业能力',
            '信用可查、垫资依据',
            '对账依据、支付工具',
            '',
          ],
        }, {
          title: '劳动方\n\n',
          imgsrc: 'C',
          list: [
            '安全打工、及时结账',
            '累计工时、信用评价体系',
            '职场报告',
            '信息交流',
            '积分消费渠道',
            '培训',
            '',
          ],
        },
      ],
      datalistx: [
        {
          imageurl: require('@/assets/image/contentsvg/book.svg'),
          title: '信息真实性\n',
          list: [
            '简历造假、招聘造假、合同造假\n',
          ],
        }, {
          imageurl: require('@/assets/image/contentsvg/house.svg'),
          title: '信息孤岛\n',
          list: [
            '简历信息真实性核验难，企业资质真实性核验难',
          ],
        }, {
          imageurl: require('@/assets/image/contentsvg/note.svg'),
          title: '人才评价体系',
          list: [
            '数据不全、模型缺乏、评价机制缺失\n',
          ],
        },
      ],
      datalistx2: [
        {
          imageurl: require('@/assets/image/contentsvg/box.svg'),
          title: '产业链整合\n',
          list: [
            '将用工的相关方行为都整合到平台中',
            '实现完整的行业信息流',
            '',
          ],
        }, {
          imageurl: require('@/assets/image/contentsvg/tie.svg'),
          title: '数据整合\n\n',
          list: [
            '长期积累形成的平台大数据也可以为用工方提供',
            '更合理的用工建议',
            '提高用工方的工作效率',
            '',
          ],
        }, {
          imageurl: require('@/assets/image/contentsvg/fly.svg'),
          title: '合同区块链化\n',
          list: [
            '将用工合同上链',
            '合同信息上链后无法篡改',
            '达到多方共识',
            '',
          ],
        },
      ],
      title: '区块链+人资解决方案',
      body:
          '可信用工平台将人力资源市场上相关方——用人方、中介方、劳务提供方聚集到一起，'
          + '实现人力资源供需信息的整合和数据挖掘、人才信用评级、用工数据上链及大数据分析，有效解决人才流失和供应链金融所需的可信数据问题。',
      image: require('@/assets/image/solution/human/human.svg'),
      showDetailBtn: true,
      showBuyBtn: true,
      images: [
        require('@/assets/image/solution/human/26_01.jpg'),
        require('@/assets/image/solution/human/26_02.jpg'),
        require('@/assets/image/solution/human/26_03.jpg'),
        require('@/assets/image/solution/human/26_04.jpg'),
      ],
    };
  },
};
</script>

<style scoped>
</style>
